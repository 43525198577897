<template>
    <div>
        <div class="SecondTitle">
            <span>{{ $t("enterOTP.secondTitle") }}</span>
        </div>
        <div>
            <span class="tips">{{ $t("enterOTP.jpnTips") }}</span>
        </div>

        <div style="margin-top: 20px;">
            <input id="otpShadow" ref="cardNo" type="tel" v-model="otpValue" center />
            <van-password-input :value="otpValue" :gutter="10" :mask="false" :length="otpValueLength"
                @click="showSystemKeyboard()" :focused="showKeyboard" @focus="showKeyboard = true" />
        </div>

        <div class="time-down">
            <div v-show="!this.showResendBtn">
                <div class="resend-otp">
                    <span>{{ $t("enterOTP.resendOTPCountdown") }}</span>
                    <van-count-down style="margin-left: 10px;" ref="timeDown" :time=this.number format="mm:ss"
                        :auto-start="true" @finish="timeDownFinish()" />
                </div>
                <!-- <div class="resend-otp" v-if="this.$root.$i18n.locale == 'jpn'">
                    <van-count-down style="margin-right: 10px;" ref="timeDown" :time=this.number format="mm:ss" :auto-start="true" @finish="timeDownFinish()" />
                    <span>{{ $t("enterOTP.resendOTPCountdown") }}</span>
                </div> -->
            </div>
            <van-button :loading="resendLoading" v-show="this.showResendBtn" @click="start()" size="large" round
                class="button-verify">
                {{ $t("enterOTP.resendOTP") }}
            </van-button>
        </div>

        <van-button @click="verifyOtp" :disabled="((otpValue.length != otpValueLength) || pageLoading)" size="large"
            round class="button-verify" :loading="loading">{{ $t("enterOTP.verify") }}</van-button>

        <van-dialog v-model="showDialog" :show-confirm-button="false">
            <template v-slot:title>
                <p style="text-align: left">{{ $t("enterOTP.invalidOTP") }}</p>
            </template>
            <template v-slot:default>
                <div>
                    <span>{{ $t("enterOTP.OTPRuleMessage1") }}</span><br>
                    <span>{{ $t("enterOTP.OTPRuleMessage2") }}</span>
                    <van-button style="width: 95%;margin-bottom: 10px" class="button-verify" @click="closeDialog()">
                        {{
                        $t("enterOTP.ok") }}
                    </van-button>
                </div>
            </template>
        </van-dialog>

        <van-dialog v-model="showLimitDialog" :show-confirm-button="false">
            <template v-slot:title>
                <p style="text-align: left">{{ $t("enterOTP.limit") }}</p>
            </template>
            <template v-slot:default>
                <div>
                    <span>{{ $t("enterOTP.limitMsg1") }}</span><br>
                    <span>{{ $t("enterOTP.limitMsg2") }}</span>
                    <van-button style="width: 95%;margin-bottom: 10px" class="button-verify" @click="closeLimitDialog()">
                        {{
                            $t("enterOTP.ok") }}
                    </van-button>
                </div>
            </template>
        </van-dialog>

    </div>
</template>

<script>
import {
    Dialog,
    PasswordInput,
    NumberKeyboard,
    Switch,
    Cell,
    DropdownMenu,
    DropdownItem,
    Divider,
    Button,
    Field,
    Toast,
} from "vant";
import { generateOtp, verifyOtp } from "@/api/eformApi";
import mixins from "../mixin/Registration.js";
export default {
    name: "EnterOpt",
    mixins: [mixins],
    components: {
        [Dialog.Component.name]: Dialog.Component,
        [NumberKeyboard.name]: NumberKeyboard,
        [PasswordInput.name]: PasswordInput,
        [Switch.name]: Switch,
        [Cell.name]: Cell,
        [DropdownItem.name]: DropdownItem,
        [DropdownMenu.name]: DropdownMenu,
        [Divider.name]: Divider,
        [Button.name]: Button,
        [Field.name]: Field,
    },
    watch: {
        otpValue() {
            // 非数字替换成空
            this.otpValue = this.otpValue.replace(/[^0-9]/g, "");
            if (this.otpValue.length > this.otpValueLength) {
                this.otpValue = this.otpValue.substring(0, this.otpValueLength);
            }
        },
    },
    data() {
        return {
            showKeyboard: false,
            otpValue: "",
            number: 5 * 60  * 1000,
            time: "",
            showDialog: false,
            email: "",
            otpValueLength: 6,
            pageLoading: false,
            brandName: "",
            loading: false,
            resendLoading: false,
            showResendBtn: false,
            sessionKey: "",
            showLimitDialog: false,
            tpid: this.$route.params.tpid
        };
    },
    created() {
        this.email = this.$route.params.email
        this.sessionKey = this.$route.params.sessionKey
        this.brandName = this.brandConfig.display
        console.log("EnterOtp",this)
    },
    methods: {
        timeDownFinish() {
            this.showResendBtn = true
        },
        closeDialog() {
            this.showDialog = false;
        },
        closeLimitDialog() {
            this.showResendBtn = false;
            this.$refs.timeDown.reset()
            this.showLimitDialog = false;
        },
        showSystemKeyboard() {
            this.showKeyboard = true;
            this.$refs.cardNo.focus();
        },
        verifyOtp() {
            this.pageLoading = true;
            this.loading = true;
            verifyOtp({
                brandCode: this.$route.params.brand,
                authNum: this.otpValue,
                country: this.$route.params.countryPath,
                email: this.email,
                sessionKey: this.sessionKey
            })
                .then((res) => {
                    if (res.success) {
                        if (res.data) {
                            Toast.success({
                                type: "success",
                                message: this.$t("common.toastSuccess"),
                            });
                            this.$router.push(
                                {
                                    name: 'Registration',
                                    params: {
                                        lang: this.$route.params.lang,
                                        brand: this.$route.params.brand,
                                        country: this.$route.params.countryPath,
                                        sessionKey: this.sessionKey,
                                        email: this.email,
                                        tpid: this.tpid
                                    },
                                    query: {
                                        storeId: this.$route.query.storeId,
                                    }
                                }
                            );
                            this.pageLoading = false;
                            this.loading = false;
                        } else {
                            Toast.fail({
                                type: "fail",
                                message: this.$t("common.toastFail"),
                            });
                            this.showDialog = true;
                            this.otpValue = "";
                            this.pageLoading = false;
                            this.loading = false;
                        }
                    } else {
                        Toast.fail({
                            type: "fail",
                            message: this.$t("common.toastFail"),
                        });
                        this.pageLoading = false;
                        this.loading = false;
                    }
                })
                .catch(() => {
                    Toast.fail({
                        type: "fail",
                        message: this.$t("common.toastFail"),
                    });
                    this.pageLoading = false;
                    this.loading = false;
                });
        },
        start() {
            this.resendLoading = true;
            generateOtp({
                email: this.email,
                country: this.$route.params.countryPath,
                brandCode: this.$route.params.brand,
                sessionKey: this.sessionKey
            }).then((res) => {
                if (res.success) {
                    Toast.success({
                        type: "success",
                        message: this.$t("common.toastSuccess"),
                    });
                    this.showResendBtn = false;
                    this.$refs.timeDown.reset()
                } else if (res.data == -1) {
                    this.showLimitDialog = true
                } else {
                    Toast.fail({
                        type: "fail",
                        message: this.$t("common.toastFail"),
                    });
                }
                this.resendLoading = false;
            }).catch(() => {
                this.resendLoading = false;
            });
        }
    },
};
</script>

<style scoped>
>>> .van-popup--top {
    width: 25%;
    padding-left: 16px;
}

.button-verify {
    display: inline;
    background: black;
    color: white;
    border-radius: 7px;
    margin-top: 20px;
}

.time-down {
    text-align: center;
}

.resend-otp {
    display: ruby-text;
    font-size: 14px;
    text-align: center;
    margin-top: 20px;
    font-family: Helvetica-Regular;
}

.van-password-input__security li {
    background-color: #ececec;
    border-radius: 6px;
}

>>> .van-dialog__header {
    padding-top: 0px;
    margin-left: 5%;
}

>>> .van-dialog__content {
    margin-left: 5%;
}

>>> .van-dialog {
    border-radius: 6px;
}

#otpShadow {
    border-style: none;
    width: 100;
    height: 0;
    padding: 0;
}
</style>

<style>
.noticeWidth {
    width: 100%;
}
.genWidth {
    width: max-content !important;
}
</style>